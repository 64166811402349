import { Link } from "react-router-dom";
import { TelegramIcon } from "src/shared/assets/icons";
import './DonationCard.scss';

const DONATE_LINKS = [
    {
        name: "Telegram",
        icon: <TelegramIcon/>,
        link: "https://web.tribute.tg/l/jC"
    }

]

type DonationCardProps = {
    title?: string
}

export const DonationCard = ({title}: DonationCardProps) => {
	return (
        <div className={title? 'popup-tail-content popup-donation': ''} 
            onClick={e => e.stopPropagation()}>
            {
                title ? (
                    <p className='popup-tail-content-text'>
                        {title}
                    </p>
                ) 
                : null
            }
            <div className="p-0 m-0 mt-2 flex flex-row justify-around">
                {
                    DONATE_LINKS.map((item, key) => (
                        <div className="p-0 m-0 w-[69px] donation-icons flex flex-col items-center" key={`${key}-${item}`}>
                            <Link to={item.link} target="_blank" className="flex justify-center">
                                {item.icon}
                            </Link>
                            <p className="text-center px-0 py-2 p-link">
                                {item.name}
                            </p>
                        </div>
                    ))
                }
            </div>
        </div>
	)
}