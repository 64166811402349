import React, { useCallback, useEffect, useState } from 'react';
import Map, { MapProvider, Layer, MapRef, Source } from 'react-map-gl/maplibre';
import { Navbar } from 'src/widgets/Navbar';
import 'maplibre-gl/dist/maplibre-gl.css';
import './MainPage.scss';
import { ControlPanel } from 'src/widgets/ControlPanel';
import DrawControl from 'src/features/map/draw-polygon/draw-control';
import { useUnit } from 'effector-react';
import { $areaPopup, $drawMode, $trashPopup, setAreaPopup, setPolygonCreated, setTrashPopup } from 'src/features/map/draw-polygon/store';
import { bbox } from '@turf/turf';

const MainPage: React.FC = (options: any) => {
    const mapRef = React.useRef<MapRef | null>(null);
    const drawControlRef = React.useRef<any>(null);
    const [features, setFeatures] = useState<any>({});
    const updatePolygonCreated = useUnit(setPolygonCreated);
    const drawMode = useUnit($drawMode);

    // Callback to reset the features state
    const resetFeatures = useCallback(() => {
        setFeatures({}); // Clear the features state
    }, []);

    const onUpdate = useCallback((e: any) => {
        setFeatures((currFeatures: any) => {
            const newFeatures = { ...currFeatures };
    
            for (const f of e.features) {
                newFeatures[f.id] = f; // Store the feature with its ID
    
                const areaPopup = $areaPopup.getState();
                if (areaPopup) {
                    const coordinates = (f.geometry as any).coordinates[0];
                    const sortedByY = [...coordinates].sort((a, b) => a[1] - b[1]); // Sort by latitude (Y)
                    const bottomLeft = sortedByY[0];
                    const bottomRight = sortedByY[1];

                    const centerX = (bottomLeft[0] + bottomRight[0]) / 2;
                    const centerY = bottomLeft[1]; // Keep the lowest Y

                    const bottomCenter: [number, number] = [centerX, centerY];

                    areaPopup.setLngLat(bottomCenter).setOffset([0, 50]);
                }

                // Update trash popup position
                const trashPopup = $trashPopup.getState();
                if (trashPopup) {
                    const bboxPolygon = bbox(f.geometry);
                    const upperRightCorner: [number, number] = [bboxPolygon[2], bboxPolygon[3]];
                    trashPopup.setLngLat(upperRightCorner).setOffset([0, -5]);
                }
    
            }
    
            return newFeatures;
        });
    }, []);

    const onDelete = useCallback((e: any) => {
        setFeatures((currFeatures: any) => {
            const newFeatures = { ...currFeatures };
            for (const f of e.features) {
                if (f.id) {
                    updatePolygonCreated(false);
                    drawControlRef.current?.delete(f.id);
    
                    // Remove the popup associated with the deleted polygon
                    const areaPopup = $areaPopup.getState();
                    if (areaPopup) {
                        areaPopup.remove();
                        setAreaPopup(null);
                    }

                    // Remove trash popup if it exists
                    const trashPopup = $trashPopup.getState();
                    if (trashPopup) {
                        trashPopup.remove();
                        setTrashPopup(null);
                    }

                    // Remove the feature from the state
                    delete newFeatures[f.id];
                } else {
                    console.log('Feature ID is undefined:', f);
                }
            }
            return newFeatures;
        });
    }, [updatePolygonCreated]);

    useEffect(() => {
        const trashPopup = $trashPopup.getState();
        if (trashPopup) {
            const trashButton = trashPopup.getElement()?.querySelector('.trash-button');
            if (trashButton) {
                const handleTrashClick = () => {
                    const polygonId = trashButton.getAttribute('data-polygon-id');
                    if (polygonId && features[polygonId]) {
                        onDelete({ features: [features[polygonId]] });
                    }
                };
    
                trashButton.addEventListener('click', handleTrashClick);
    
                // Cleanup event listener
                return () => {
                    trashButton.removeEventListener('click', handleTrashClick);
                };
            }
        }
    }, [onDelete, features]);

    const onSelect = useCallback((e: any) => {
        const feature = e.features[0];

        if (feature) {
            const bboxPolygon = bbox(feature);
            const upperRightCorner: [number, number] = [bboxPolygon[2], bboxPolygon[3]];

            // Update trash popup position
            const trashPopup = $trashPopup.getState();
            if (trashPopup) {
                trashPopup.setLngLat(upperRightCorner).setOffset([0, -5]);
            }
        }
    }, []);

    return (
        <>
            <Navbar onSelectAreaClick={resetFeatures} />
            <MapProvider>
                <Map
                    ref={mapRef}
                    initialViewState={{
                        longitude: 2.351556,
                        latitude: 48.856663,
                        zoom: 9,
                    }}
                    id={"mainMap"}
                    style={{ width: '100vw', height: '100vh', position: 'absolute', top: 0, bottom: 0, left: 0, zIndex: 0 }}
                    dragRotate={false}
                    bearingSnap={0}
                    maxPitch={0}
                    minPitch={0}
                >
                    <Source type='raster'
                        tileSize={256}
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        tiles={['https://tile.openstreetmap.org/{z}/{x}/{y}.png']}
                    >
                        <Layer type='raster' source='raster' />
                    </Source>
                    <ControlPanel plgnGeom={features} mapRef={mapRef} />
                    {
                        drawMode ? (
                            <DrawControl
                                ref={drawControlRef}
                                position="bottom-right"
                                displayControlsDefault={true}
                                controls={{
                                    polygon: true,
                                    trash: true,
                                }}
                                defaultMode={'draw_square'}
                                onCreate={onUpdate}
                                onUpdate={onUpdate}
                                onDelete={onDelete}
                                onSelect={onSelect}
                            />
                        ) : null
                    }
                </Map>
            </MapProvider>
        </>
    );
};

export default MainPage;