import { createEffect, createEvent, createStore, sample } from "effector";
import { SpatialApi } from "src/shared/api";
import { Categories, SaveSendData, Formats, Sources, CategoriesAndFormatsResponse } from "src/shared/api/spatialhub-api";

// Reset all fields
export const resetAllFields = createEvent();

// Data format
export const setDataFormatEvent = createEvent<string>();
export const $dataFormat = createStore<string | null>(null)
  .on(setDataFormatEvent, (_, current) => current)
  .reset(resetAllFields);

// Email store
export const setEmailEvent = createEvent<string>();
export const $emailStore = createStore<string | null>(null)
  .on(setEmailEvent, (_, current) => current)
  .reset(resetAllFields);

// Get sources
export const getSources = createEffect(() =>
  SpatialApi.backend.backendApiSourcesList().then(({ data }) => data as Sources[])
);
export const $sourcesStore = createStore<Sources[]>([])
  .on(getSources.doneData, (_, payload) => payload);
export const $loadingSources = getSources.pending;

// Selected source_id
export const setSourceIdEvent = createEvent<number>();
export const $selectedSourceId = createStore<number | null>(1)
  .on(setSourceIdEvent, (_, id) => id);

// Fetch Categories and Formats based on selected source_id
export const getCategoriesAndFormats = createEffect((sourceId: number) => {  
  return SpatialApi.backend
    .backendApiSourceInfoList({ source_id: sourceId })
    .then(({ data }) => {
      return data;
    });
});

export const $categoriesAndFormats = createStore<CategoriesAndFormatsResponse | null>(null)
  .on(getCategoriesAndFormats.doneData, (state, payload) => {
    return {
      ...state,
      source_id: payload.source_id,
      categories: payload.categories,
      formats: payload.formats,
    };
  });

export const $loadingCategoriesAndFormats = getCategoriesAndFormats.pending;

// Separate stores for categories and formats
export const $categoriesStore = createStore<Categories[]>([])
  .on(getCategoriesAndFormats.doneData, (_, payload) => payload?.categories || []);

export const $formatsStore = createStore<Formats[]>([])
  .on(getCategoriesAndFormats.doneData, (_, payload) => payload?.formats || []);

// Reactively load categories and formats when source_id changes
sample({
  clock: $selectedSourceId,
  filter: (sourceId) => {
    return sourceId !== null;
  },
  fn: (sourceId) => {
    return sourceId as number;
  },
  target: getCategoriesAndFormats,
});


// Send Data
export const sendData = createEffect((data: SaveSendData) =>
  SpatialApi.backend.backendApiSendEmailCreate(data)
);
