import { createEvent, restore, createStore } from "effector";

export const setDrawMode = createEvent<string | null>();
export const $drawMode = restore(setDrawMode, null);

// Event to track polygon creation
export const setPolygonCreated = createEvent<boolean>();
export const $isPolygonCreated = createStore<boolean>(false).on(setPolygonCreated, (_, isCreated) => isCreated);

// Add popup state
export const setAreaPopup = createEvent<maplibregl.Popup | null>();
export const $areaPopup = createStore<maplibregl.Popup | null>(null).on(setAreaPopup, (_, popup) => popup);

// Add Trash (delete) popup state
export const setTrashPopup = createEvent<maplibregl.Popup | null>();
export const $trashPopup = createStore<maplibregl.Popup | null>(null).on(setTrashPopup, (_, popup) => popup);