import { useLocation } from 'react-router-dom';
import { useState, useEffect } from "react";
import { useUnit } from "effector-react";
import { AddIcon, SpHubLogoIcon, TelegramIcon, LinkedInIcon } from "src/shared/assets/icons";
import { AppLink, Button, Dialog, Divider } from "src/shared/ui";
import { $areaPopup, $trashPopup, setAreaPopup, setTrashPopup } from 'src/features/map/draw-polygon/store';
import { DrawStore } from 'src/features/map/draw-polygon';
import { DonationCard } from "src/widgets/DonationCard";
import './Navbar.scss';
import FeedbackCard from 'src/widgets/FeedbackCard';

interface NavbarProps {
    className?: string;
    onSelectAreaClick?: () => void;
}

const DONATE_TITLE = "Support us with donations";
const DONATE_TEXT = "Your financial support helps us continue in our mission, implement new features and achieve our goals of easy and quality geospatial data collection";

export const Navbar = ({ className, onSelectAreaClick }: NavbarProps) => {
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [openAboutDialog, setOpenAboutDialog] = useState<boolean>(false);
    const setMode = useUnit(DrawStore.setDrawMode);
    const location = useLocation();
    const EmptyNavbarPaths = [ '/feedback', '/feedback/', '/expired', '/expired/' ]; // List of pages to exclude from the navbar

    // Reset drawing state when navigating to any page
    useEffect(() => {
        setMode(null);
        DrawStore.setPolygonCreated(false);
    }, [location.pathname, setMode]);

    const toggleDonationDialog = () => {
        setOpenDialog(!openDialog);
    }

    const toggleAboutDialog = () => {
        setOpenAboutDialog(!openAboutDialog);
    };

    const onClick = () => {
        // Reset the mode
        setMode(null);

        // Delete popups
        const trashPopup = $trashPopup.getState();
        if (trashPopup) {
            trashPopup.remove();
            setTrashPopup(null);
        }

        const areaPopup = $areaPopup.getState();
        if (areaPopup) {
            areaPopup.remove();
            setAreaPopup(null);
        } 

        // Reset polygon drawing state
        DrawStore.setPolygonCreated(false);

        // Call the onSelectAreaClick callback to reset features
        if (onSelectAreaClick) {
            onSelectAreaClick();
        }

        // Set the mode again after a short delay
        setTimeout(() => {
            setMode('draw_square');
        }, 0);
    }

    if (EmptyNavbarPaths.includes(location.pathname)) {
        return ( <div className="Navbar">
                    <div className="Navbar_main__wrapper">
                        <AppLink className='Navbar_main Navbar_link' to={'/'} > SpatialHub</AppLink>
                    </div>
                    <div className="mr-2">
                        <Button
                            variant={'blue'}
                            onClick={toggleDonationDialog}>
                            {'Donate'}
                        </Button>
                    </div>
                    <Dialog opened={openDialog} 
                            title={DONATE_TITLE}
                            onClose={toggleDonationDialog}
                            className="popup-donation-btn">
                        <div className='popup-text mb-4'>
                            {DONATE_TEXT}
                        </div>
                        <DonationCard/>
                    </Dialog>
                </div> );
    } else {
    return (
        <div className="Navbar">
            <div className="Navbar_main__wrapper">
                <AppLink className='Navbar_main Navbar_link' to={'/'}>SpatialHub</AppLink>
            </div>
            <div className="Navbar_other">
                <Button
                    className='select-area-btn'
                    leftIcon={<AddIcon style={{ marginRight: '4px' }}/>}
                    onClick={onClick}>
                    {'Select Area'}
                </Button>
                <Divider/>
                <Button
                    className='about-us-btn'
                    onClick={toggleAboutDialog}>About Us
                </Button>
            </div>

            <div className="mr-2">
                <Button
                    variant={'blue'}
                    onClick={toggleDonationDialog}>
                    {'Donate'}
                </Button>
            </div>

            <Dialog opened={openDialog} 
                    title={DONATE_TITLE}
                    onClose={toggleDonationDialog}
                    className="popup-donation-btn">
				<div className='popup-text mb-4'>
					{DONATE_TEXT}
				</div>
                <DonationCard/>
            </Dialog>
            <Dialog 
                opened={openAboutDialog}
                onClose={toggleAboutDialog}
                tailContent={<FeedbackCard/>}
                className="about-dialog">
                <div className="popup-logo-wrapper">
                    <SpHubLogoIcon className="SpatialHubLogo" />
                </div>
                <div className="popup-title">
                    {"About SpatialHub"}
                </div>
                <div className='popup-text-1'>
                    <p>{"We are a group of passionate enthusiasts working to prepare and provide spatial data for everyone."}</p>
                    <p><strong>{"Our goal is to create a free service that will be useful for all"}</strong>{" — from beginners to professionals — offering access to spatial data that can help improve our world."}</p>
                    <p>{"Our mission is to make spatial data accessible and free, so enthusiasts from around the globe can work on their projects and make a difference!"}</p>
                </div>
                <div className="follow-us">
                    <p><strong>{"Follow us on"}</strong></p>
                    <div className="social-media-icons">
                        <a href="https://www.linkedin.com/company/spatialhub-online" target="_blank" rel="noopener noreferrer">
                            <LinkedInIcon />
                        </a>
                        <a href="https://t.me/spatialhub" target="_blank" rel="noopener noreferrer">
                            <TelegramIcon />
                        </a>
                    </div>
                </div>
            </Dialog>
        </div>
    );
    }
}