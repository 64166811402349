import { useUnit } from "effector-react";
import { $loadingSources, $sourcesStore } from "./store";
import { LoaderIcon } from "src/shared/assets/icons";
import { useEffect, useRef } from "react";

export const SourcesList = ({onChange, onClose}: {onChange: (id:number) => void, onClose: () => void}) => {
	const ref = useRef(null);

	useEffect(() => {
		const inputBox = document.querySelector('.selected-source-box');

		const handleClickOutside = (event: MouseEvent) => {
			if (
				!(ref.current as unknown as HTMLDivElement).contains(event.target as Node) &&
				!inputBox?.contains(event.target as Node)
			) {
				onClose();
			}
		};
	
		document.addEventListener('mousedown', handleClickOutside);
		return () => document.removeEventListener('mousedown', handleClickOutside);
	  }, [onClose]);


	const [sources, loadingSources] = useUnit([
		$sourcesStore,
		$loadingSources,
	]);

	return (
		<ul className="sources-dropdown" ref={ref}>
			{loadingSources ? (
			<LoaderIcon />
			) : (
			sources?.map(({ id, name }) => (
			  <li key={id} 
			  	  className="sources-dropdown-item" 
				  onClick={() => onChange(id)}>
				{name}
			  </li>
			))
			)}
		</ul>
	)
}