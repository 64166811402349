import { DrawCustomMode, DrawCustomModeThis } from '@mapbox/mapbox-gl-draw';
import { GeoJSON } from 'geojson';
import proj4 from 'proj4';
import * as jsts from 'jsts';
import { setPolygonCreated, setAreaPopup, setTrashPopup } from 'src/features/map/draw-polygon/store';
import maplibregl from 'maplibre-gl';
import { v4 as uuidv4 } from 'uuid'; // Import uuid for generating unique IDs
import { bbox } from '@turf/turf';

const updatePopupPosition = (
    areaPopup: any, 
    coordinates: any[], 
    map: maplibregl.Map
) => {
    // Find the two lowest Y-coordinate points
    const sortedByY = [...coordinates].sort((a, b) => a[1] - b[1]); // Sort by latitude (Y)
    
    const bottomLeft = sortedByY[0]; 
    const bottomRight = sortedByY[1];
  
    // Calculate the midpoint of the bottom line
    const centerX = (bottomLeft[0] + bottomRight[0]) / 2;
    const centerY = bottomLeft[1]; // Keep the lowest Y
  
    const bottomCenter: [number, number] = [centerX, centerY];
  
    areaPopup.setLngLat(bottomCenter)
         .setOffset([0, 50]); // Adjust position below the polygon
    areaPopup.addTo(map);
};  

const calculateAreaAndUpdatePopup = (state: { square: any; areaPopup?: any; }, tempCoordinates: any[], tempCoordinates3857: any[], map: maplibregl.Map) => {
    const { square, areaPopup } = state;

    if (tempCoordinates3857.length < 4) return 0; // Ensure valid polygon

    const geometryFactory = new jsts.geom.GeometryFactory();
    const shell = tempCoordinates3857.map((coord: number[]) => new jsts.geom.Coordinate(coord[0], coord[1]));
    const linearRing = geometryFactory.createLinearRing(shell);
    const polygon = geometryFactory.createPolygon(linearRing);

    const areaSquareMeters = polygon.getArea();
    const area = areaSquareMeters / 1e6; // Convert to km²

    areaPopup.setHTML(`Area: ${area.toFixed(1)} / ${square.properties.areaLimit} km²`);

    // Get the popup content element
    const popupContent = areaPopup.getElement()?.querySelector('.maplibregl-popup-content');

    if (popupContent) {
        // Remove any previously set classes
        popupContent.classList.remove('panel-within-limit', 'panel-exceeds-limit');

        // Add the appropriate class based on the area condition
        if (area > square.properties.areaLimit) {
            popupContent.classList.add('panel-exceeds-limit'); // Exceeds area limit
        } else {
            popupContent.classList.add('panel-within-limit'); // Within area limit
        }
    }

    updatePopupPosition(areaPopup, tempCoordinates, map);

    return area;
};

const DrawSquare: DrawCustomMode = {
    onSetup(opts) {
        const polygonId = uuidv4(); // Generate a unique ID for the polygon

        const square = this.newFeature({
            id: polygonId,
            type: 'Feature',
            properties: {
                areaLimit: 4000, // Max area in km²
                area_exceed: false,
                init_state: true,
            },
            geometry: {
                type: 'Polygon',
                coordinates: [[[0, 0], [0, 0], [0, 0], [0, 0], [0, 0]]],
            },
        });

        this.addFeature(square);
        this.setActionableState({ trash: true, combineFeatures: false, uncombineFeatures: false });
        this.map.getCanvas().style.cursor = 'crosshair';

        let tooltip = document.getElementById('draw-tooltip');
        if (!tooltip) {
            tooltip = document.createElement('div');
            tooltip.id = 'draw-tooltip';
            tooltip.className = 'draw-tooltip';
            tooltip.innerText = 'Click to start drawing';
            document.body.appendChild(tooltip);
        }
        tooltip.style.display = 'block';

        // Create Mapbox Popup with initial style
        const areaPopup = new maplibregl.Popup({
            closeButton: false, // Disable close button
            closeOnClick: false, // Close on clicking the map
            closeOnMove: false, // Close when the map is moved
        }).setHTML(`Area: 0.0 / ${square.properties?.areaLimit ?? 0} km²`);

        setAreaPopup(areaPopup);        
        // Set style
        areaPopup.on('open', () => {
            const popupContent = areaPopup.getElement()?.querySelector('.maplibregl-popup-content');
            if (popupContent) {
                popupContent.classList.add('panel-within-limit');
            }
        });

        return { square, drawing: false, clickCount: 0, startCoordinates: [], areaPopup, tooltip };
    },

    onClick(state, e) {
        const { square, clickCount, tooltip, areaPopup } = state;

        if (clickCount === 0) {
            state.startCoordinates = [e.lngLat.lng, e.lngLat.lat];
            state.drawing = true;
            state.clickCount = 1;
            tooltip.style.display = 'none';
            // Do not show the popup yet
        } else if (clickCount === 1) {
            const coordinates = square.getCoordinates()[0];
            const area = calculateAreaAndUpdatePopup(state, coordinates, coordinates.map((coord: any) => proj4('EPSG:4326', 'EPSG:3857', coord)), this.map as unknown as maplibregl.Map);

            if (area > square.properties.areaLimit) {
                return;
            }

            // Set popup position after drawing is done
            const bottomY = Math.min(...coordinates.map((coord: any[]) => coord[1]));
            const bottomPoints = coordinates.filter((coord: number[]) => coord[1] === bottomY);
            const centerX = bottomPoints.reduce((sum: any, coord: any[]) => sum + coord[0], 0) / bottomPoints.length;
            const bottomCenter: [number, number] = [centerX, bottomY];

            areaPopup.setLngLat(bottomCenter);
            areaPopup.addTo(this.map);

            state.drawing = false;
            state.clickCount = 0;
            square.properties.init_state = false;
            this.map.fire('draw.update', { features: [square.toGeoJSON()] });
            this.changeMode('simple_select');
        }
    },

    onMouseMove(state, e) {
        if (state.tooltip) {
            state.tooltip.style.left = `${e.originalEvent.clientX + 8}px`;
            state.tooltip.style.top = `${e.originalEvent.clientY + 8}px`;
        }

        if (state.clickCount === 1) {
            const { square } = state;
            const start = state.startCoordinates;
            const current = [e.lngLat.lng, e.lngLat.lat];

            const start3857 = proj4('EPSG:4326', 'EPSG:3857', start);
            const current3857 = proj4('EPSG:4326', 'EPSG:3857', current);
            const dx = current3857[0] - start3857[0];
            const dy = current3857[1] - start3857[1];
            const sideLength = Math.max(Math.abs(dx), Math.abs(dy));

            const tempCoordinates3857 = [
                start3857,
                [start3857[0] + sideLength * Math.sign(dx), start3857[1]],
                [start3857[0] + sideLength * Math.sign(dx), start3857[1] + sideLength * Math.sign(dy)],
                [start3857[0], start3857[1] + sideLength * Math.sign(dy)],
                start3857
            ];

            const tempCoordinates = tempCoordinates3857.map(coord => proj4('EPSG:3857', 'EPSG:4326', coord));
            square.setCoordinates([tempCoordinates]);

            const area = calculateAreaAndUpdatePopup(state, tempCoordinates, tempCoordinates3857, this.map as unknown as maplibregl.Map);

            if (area > state.square.properties.areaLimit) {
                state.square.properties.area_exceed = true;
            } else {
                state.square.properties.area_exceed = false;
            }

            this.map.fire('draw.update', { features: [square.toGeoJSON()] });
        }
    },

    onStop(state) {
        const { square, areaPopup } = state;
        const bboxPolygon = bbox(square);
        const upperRightCorner = [bboxPolygon[2], bboxPolygon[3]];
    
        // Create trash popup when polygon is created
        const trashPopup = new maplibregl.Popup({
            closeButton: false,
            closeOnClick: false,
            closeOnMove: false,
            offset: [0, -5],
        }).setLngLat([upperRightCorner[0], upperRightCorner[1]])
          .setHTML(`<button class="trash-button" data-polygon-id="${square.id}"></button>`)
          .addTo(this.map as unknown as maplibregl.Map);
        
        setPolygonCreated(true);
        setAreaPopup(areaPopup);
        setTrashPopup(trashPopup);
    
        this.map.getCanvas().style.cursor = 'pointer';
        this.map.fire('draw.update', { features: [square.toGeoJSON()] });
    },
    
    toDisplayFeatures(
        this: DrawCustomModeThis & DrawCustomMode<any, any>,
        state: any,
        geojson: GeoJSON,
        display: (geojson: GeoJSON) => void
    ): void {
        display(geojson);
    },
};

export default DrawSquare;