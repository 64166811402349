import { Api } from "./spatialhub-api";

/**
 * Retrieves the value of a cookie with the specified name from the current document.
 *
 * @param {string} name - The name of the cookie to retrieve.
 * @return {string|null} The value of the cookie, or null if the cookie is not found.
 */
function getCookie(name: string) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

const csrfToken = getCookie('csrftoken');
const authToken = process.env.REACT_APP_AUTH_TOKEN;
const baseUrl = process.env.REACT_APP_BASE_URL;




export const SpatialApi = new Api({
  baseUrl: baseUrl,
  baseApiParams: {
    headers: {
      'Authorization': `${authToken}`,
      ...(csrfToken && { 'X-CSRFToken': csrfToken })
    }
  },
});
