import { ArrowIcon, InfoIcon, LoaderIcon } from 'src/shared/assets/icons';
import { MapRef } from 'react-map-gl/maplibre';
import { searchLocationPhoton } from 'src/features/map/photon-search/photon-search';
import './styles.scss';
import { Button, Checkbox } from 'src/shared/ui';
import { useEffect, useMemo, useState, useCallback, useRef } from 'react';
import { useUnit } from 'effector-react';
import {
  $categoriesStore,
  $dataFormat,
  $emailStore,
  sendData,
  setDataFormatEvent,
  setEmailEvent, 
  resetAllFields,
  $sourcesStore,
  getSources,
  setSourceIdEvent,
  $selectedSourceId,
  $categoriesAndFormats,
  $loadingCategoriesAndFormats,
  getCategoriesAndFormats,
} from './store';
import { $isPolygonCreated, setDrawMode, setPolygonCreated, 
         $areaPopup, $trashPopup, setAreaPopup, setTrashPopup } from 'src/features/map/draw-polygon/store';
import { GisFeatureEnum } from 'src/shared/api/spatialhub-api';
import React from 'react';
import { useStatusCard } from 'src/widgets/StatusCard';
import { DonationCard } from '../DonationCard';
import { Tooltip } from './Tooltip';
import { SourcesList } from './Sources-List';

type ControlPanelProps = {
  plgnGeom: any;
  mapRef: React.RefObject<MapRef | null>;
}

type LayerControl = {
  name: string;
  checked: boolean;
}

export const ControlPanel = ({ plgnGeom, mapRef }: ControlPanelProps) => {

  const [sources, selectedSourceId, categoriesAndFormats, loadingCategoriesAndFormats] = useUnit([
    $sourcesStore,
    $selectedSourceId,
    $categoriesAndFormats,
    $loadingCategoriesAndFormats,
  ]);

  const [layers, setLayers] = useState<LayerControl[] | []>([]);
  const [allLayersSelected, setAllLayersSelected] = useState<boolean>(false);
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(null);
  const isPolygonCreated = useUnit($isPolygonCreated);

  const setSelectedDataFormat = useUnit(setDataFormatEvent);
  const selectedDataFormat = useUnit($dataFormat);
  const email = useUnit($emailStore);
  const [emailError, setEmailError] = useState<string | null>(null);
  const setEmail = useUnit(setEmailEvent); // Use Effector's setEmailEvent
  const categories = useUnit($categoriesStore);
  const resetFields = useUnit(resetAllFields);
  const { showStatus, ModalComponent } = useStatusCard();

  // Sources
  const [selectedSource, setSelectedSource] = useState<number>(1);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const currentSource = useMemo(() => 
    sources.find((source) => source.id === selectedSource), 
  [sources, selectedSource]);

  const handleDropdownToggle = () => {
    setDropdownOpen((prev) => {
      if (!prev) {
        setIsFormatDropdownOpen(false);
      }
      return !prev;
    });
  };

  // Format
  const [isFormatDropdownOpen, setIsFormatDropdownOpen] = useState(false);

  const handleFormatDropdownToggle = () => {
    setIsFormatDropdownOpen((prev) => {
      if (!prev) {
        setDropdownOpen(false);
      }
      return !prev;
    });
  };
  
  const handleFormatChange = (format: string) => {
    setSelectedDataFormat(format);
    setIsFormatDropdownOpen(false);
  };
  
  const handleSourceChange = (id: number) => {
    const newSourceId = id;
    setSelectedSource(newSourceId);
    setSourceIdEvent(newSourceId);
    getCategoriesAndFormats(newSourceId);
    setDropdownOpen(false);
  };

  useEffect(() => {
    getSources();
    setSourceIdEvent(1);
    getCategoriesAndFormats(1);
  }, []);

  // Search
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [clicked, setClicked] = useState(false);
  const minimumSearchLength = 2;

  const [isOpenSourceInfo, setIsOpenSourceInfo] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const toggleSourceInfo = () => {
      if (buttonRef.current) {
          const rect = buttonRef.current.getBoundingClientRect();
          setTooltipPosition({
              top: rect.top + window.scrollY + rect.height / 2,
              left: rect.right + window.scrollX + 10,  // Align tooltip with button
          });
      }
      setIsOpenSourceInfo(!isOpenSourceInfo);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setClicked(false);
    setSearchQuery(value);

    if (value === '') {
      // If the search box is cleared, hide the dropdown and clear results
      setSearchResults([]);
      setIsDropdownVisible(false);
    } else {
      // Show dropdown when typing
      setIsDropdownVisible(true);
    }
  };

  // Define onFocus function outside the input
  const handleSearchFocus = () => {
    // If the search box is empty, clear results and hide dropdown
    if (searchQuery.trim() === '') {
      setSearchResults([]);
      setIsDropdownVisible(false);
    } else if (searchResults.length > minimumSearchLength) {
      // Show dropdown only if there are valid results
      setIsDropdownVisible(true);
      setClicked(false);
    }
  };

  // Search
  const debounceSearch = useCallback(() => {
    if (clicked) return; // Skip fetching if a result was clicked

    let timer: NodeJS.Timeout;
    if (searchQuery.length >= minimumSearchLength) {
        timer = setTimeout(async () => {
            try {
                const results = await searchLocationPhoton(searchQuery);
                setSearchResults(results);
                setIsDropdownVisible(true);
            } catch (error) {
                console.error('Error searching location:', error);
            }
        }, 300);
    } else {
        setSearchResults([]);
        setIsDropdownVisible(false);
    }

    return () => clearTimeout(timer);
  }, [searchQuery, clicked]);


  useEffect(() => {
    debounceSearch();
  }, [searchQuery, debounceSearch]);

  const handleResultClick = (result: any) => {
    const [longitude, latitude] = result.geometry.coordinates;
    const bounds = result.properties.extent;
    if (bounds === undefined) {
      mapRef.current?.flyTo({ center: [longitude, latitude], zoom: 9 });  
    } else {
      mapRef.current?.fitBounds(bounds);  
    }
    
    const displayName = result.properties.name || `${result.properties.street}, ${result.properties.housenumber}` || result.properties.street || result.properties.housenumber || '';
    setSearchQuery(displayName);
    setIsDropdownVisible(false);
    setClicked(true);
};

  // Hide dropdown when clicking outside the search box or dropdown
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
        const dropdown = document.querySelector('.search-dropdown');
        const inputBox = document.querySelector('.search-box input');
        if (
            !dropdown?.contains(event.target as Node) &&
            !inputBox?.contains(event.target as Node)
        ) {
            setIsDropdownVisible(false);
        }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    setDataFormatEvent('');
    setAllLayersSelected(false);
  
    if (categories) {
      setLayers(categories?.map(({ name }) => ({ name, checked: false })) ?? []);
    }
  }, [categories]);

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@,]+@[^\s@,]+\.[^\s@,]+$/;
    return emailRegex.test(email);
  };

  const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setEmail(value); // Update email state immediately
    setEmailError(null); // Clear error while typing
  
    if (typingTimeout) {
      clearTimeout(typingTimeout); // Reset the timer if user is still typing
    }
  
    // Wait 3 seconds after the user stops typing before validating
    const timeout = setTimeout(() => {
      if (value !== '' && !validateEmail(value)) {
        setEmailError('Invalid e-mail format'); // Show error after delay
      }
    }, 1500); // N seconds delay
  
    setTypingTimeout(timeout); // Save the timeout ID
  };

  const onSelectLayer = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    setLayers((prev) => {
      const idx = prev.findIndex(({ name }) => name === event.target.name);
      const newList = [...prev];
      newList[idx] = { name: name, checked: checked };
      return newList;
    });
  }

  const onSelectFormat = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedDataFormat(event.target.value);
  }

  const onSelectAllLayers = () => {
    setAllLayersSelected(prevState => !prevState); // Toggle the state
    setLayers(prev => prev.map(item => ({ ...item, checked: !allLayersSelected })));
  }

  const isDisabledBtn = useMemo(() =>
    !(layers.filter(item => item.checked === true).length > 0
      && (email ?? '').toString().length > 0
      && (selectedDataFormat ?? '').length > 0
      && isPolygonCreated)
    , [layers, email, selectedDataFormat, isPolygonCreated]);

  const onSubmit = async () => {
    if (emailError) return; // Prevent submission if email is invalid

    await sendData({
      type: GisFeatureEnum.Feature,
      geometry: plgnGeom[Object.keys(plgnGeom)[0]].geometry,
      properties: {
        email: email ?? '',
        selectedCategories: layers.filter(({ checked }) => checked === true).map(({ name }) => name).join(", "),
        fileFormat: selectedDataFormat ?? '',
        sourceId: String(selectedSource),
      }
    }).then(() => {
      resetFields();
      setLayers(prev => prev.map(item => ({ ...item, checked: false })))
      setSearchQuery('');
      showStatus("ok");
      setDrawMode(null);
      setPolygonCreated(false)
      
      const areaPopup = $areaPopup.getState();
      if (areaPopup) {
          areaPopup.remove();
          setAreaPopup(null);
      }

      // Remove trash popup if it exists
      const trashPopup = $trashPopup.getState();
      if (trashPopup) {
          trashPopup.remove();
          setTrashPopup(null);
      }

      // Deselect all layers
      setAllLayersSelected(false);
    
    }).catch(() => {
      showStatus("error")
    });
  }

  return (
    <div className="control-panel">
        {/* Search Box */}
        <div className="search-box">
          <input
            type="text"
            value={searchQuery}
            onChange={handleSearchChange}
            placeholder="Search for a location"
            onFocus={handleSearchFocus}
        />
        {searchQuery && (
          <button
            className="clear-button"
            onClick={() => {
              setSearchQuery('');
              setIsDropdownVisible(false);
              setClicked(false);
              setSearchResults([]);
            }}
          >
            &times;
          </button>
        )}
          {isDropdownVisible && (
              <ul className="search-dropdown">
                  {searchResults.map((result, index) => {
                      const { name, type, state, country, street, housenumber } = result.properties;
                      if (type === "house" && name === undefined) {
                        return (
                          <li key={index} onClick={() => handleResultClick(result)}>
                              <div className="result-name">{street}, {housenumber}</div>
                              <div className="result-location">
                              {type ? `${type}, ` : ''}{state ? `${state}, ` : ''}{country}
                              </div>
                          </li>
                      );
                      } else 
                      return (
                          <li key={index} onClick={() => handleResultClick(result)}>
                              <div className="result-name">{name}</div>
                              <div className="result-location">
                              {type ? `${type}, ` : ''}{state ? `${state}, ` : ''}{country}
                              </div>
                          </li>
                      );
                  })}
              </ul>
          )}
      </div>

      {/* Categories Panel */}
      <div className="layers-panel" key="categories">
        <div className="layers-header">
          <div className="layers-title">{'Sources'}</div>
        </div>
        <div className='relative w-full h-auto mb-2 box-border' ref={dropdownRef}>
          <div className='flex justify-between gap-1'>
            <button className="selected-source-box grow-1"
                    onClick={handleDropdownToggle}>
              {currentSource?.name ?? ''}
              <ArrowIcon className={`arrow-icon ${isDropdownOpen ? 'open' : 'closed'}`}/>
            </button>
            <button className='selected-source-box shrink-1 grow-0 max-w-[36px] max-h-[36px] p-0 m-0 px-[8px]'
              ref={buttonRef} onClick={toggleSourceInfo}>
              <InfoIcon />
            </button>

            {isOpenSourceInfo && (
              <Tooltip 
                sourceInfo={currentSource} 
                onClose={() => setIsOpenSourceInfo(false)}
                position={tooltipPosition} 
              />
            )}
          </div>
          {isDropdownOpen ? <SourcesList onChange={handleSourceChange} onClose={() => setDropdownOpen(!isDropdownOpen)}/> : null}
        </div>

        <div className="layers-header">
          <div className="layers-title">{'Categories'}</div>
          <Button onClick={onSelectAllLayers} className='hover:bg-transparent'>
            {allLayersSelected ? 'Deselect all' : 'Select all'}
          </Button>
        </div>
        {loadingCategoriesAndFormats ? (
          <LoaderIcon
            style={{
              display: 'flex',
              margin: 'auto',
              height: '100%',
            }}
          />
        ) : (
          <div className='w-full overflow-y-auto'>
            {categoriesAndFormats?.categories?.map(({ name }, idx) => (
              <div className="layers-item" key={`category-${idx}`}>
                <Checkbox
                  label={name}
                  checked={layers.some((layer) => layer.name === name && layer.checked)}
                  onChange={(e) => onSelectLayer(e)}
                />
              </div>
            ))}
          </div>
        )}
        <div className="layers-header format-header">
          <div className="layers-title">{'Formats'}</div>
        </div>
          <div className='relative w-full h-auto mb-2 box-border'>
            <div className='flex justify-between gap-1'>
            <button className="selected-format-box grow-1"
                    onClick={handleFormatDropdownToggle}>
              {selectedDataFormat || 'Select a format'}
              <ArrowIcon className={`arrow-icon ${isFormatDropdownOpen ? 'open' : 'closed'}`}/>
            </button>
            </div>
            {isFormatDropdownOpen && (
                <ul className="format-dropdown">
                  {categoriesAndFormats?.formats
                    ?.slice()
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map(({ name }, idx) => (
                      <li
                        key={`format-${idx}`}
                        className="format-dropdown-item"
                        onClick={() => handleFormatChange(name)}
                      >
                        {name}
                      </li>
                    ))}
                </ul>
              )}
          </div>
        </div>
      <div className="email-panel" key='email-area'>
            <div className='layers-title' key={'email-title'}>
                {'E-mail'}
            </div>
            <div className='email-wrapper'>
                <input
                    type="text"
                    placeholder='E-mail@example.com'
                    className={`email-content ${emailError ? 'email-error' : ''}`}
                    onChange={onEmailChange}
                    value={email ?? ''}
                />
                {emailError && (
                <div className="email-error-message">
                    {/* <InfoIcon className="info-icon"/> */}
                    {emailError}
                </div>
              )}

            </div>
        </div>

      <Button className={'btn-submit'} disabled={isDisabledBtn || !!emailError}
              type='submit'
              onClick={onSubmit}>
          {'Send data'}
      </Button>
      <ModalComponent>
        <DonationCard title={'Support us with donations'}/>
      </ModalComponent>
    </div>
  );
}