import { useEffect, useRef } from "react";
import { createPortal } from "react-dom";

export const Tooltip = ({ sourceInfo, onClose, position }: { sourceInfo: any, onClose: () => void, position: { top: number, left: number } }) => {
    const ref = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [onClose]);

    return createPortal(
        <div
            className="tooltip-info"
            ref={ref}
            style={{
                position: 'absolute',
                top: position.top,
                left: position.left,
                zIndex: 9999,
            }}
        >
            <div className='tooltip-arrow'></div>
            <p className='w-full font-bold'>About:</p>
            {sourceInfo.description ?? ''}
            <a
                target='_blank'
                rel="noreferrer"
                href={sourceInfo.url}
                className='block w-full underline text-[#005BD3]'
            >
                License
            </a>
        </div>,
        document.body
    );
};
