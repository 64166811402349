import { SpatialApi } from 'src/shared/api/';

export interface PhotonSearchResult {
  geometry: {
    coordinates: [number, number];
  };
  properties: {
    name: string;
    country: string;
  };
}

export const searchLocationPhoton = async (query: string): Promise<PhotonSearchResult[]> => {
  try {
    const response = await SpatialApi.backend.backendApiSearchLocationRetrieve({
      q: query,
      limit: 3,
      lang: 'en',
    });

    if (response.data && response.data.features) {
      return response.data.features.map((feature: any) => ({
        geometry: feature.geometry,
        properties: feature.properties,
      }));
    }

    return [];
  } catch (error) {
    console.error('Error fetching data from API:', error);
    throw new Error('Failed to fetch location data.');
  }
};

